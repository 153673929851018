<template>
  <b-container fluid>
    <b-row class="text-white bg-kpmg-dark-blue py-4 mb-4">
      <b-col>
        <h1 class="kstyle">External notifications</h1>
      </b-col>
    </b-row>
    <b-row>
  <b-container>
    <b-row>
      <b-col>
        <h4>Notifications are in beta testing. Emails are sent at 6am UTC.</h4>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col >
        <b-card class="mb-3">
          <h3>Notifications</h3>
          <div v-if="loading"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
          <span v-if="!loading">
        <div>
          Number of notifications: {{ this.notifications.length }}
        </div>
        <div>
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search" />
              <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
          </b-input-group>
        </div>
        <b-table id="regtable"
          striped
          hover
          :items="notifications"
          :fields="fields"
          :filter="filter"
          @row-clicked="showNotification"></b-table>
          </span>
        </b-card>

    <b-modal ref="modal" size="xl" ok-only title="Notification" v-if="!loading">
      <notification-item
        :model="'notificationexternal'"
        :notification="notification"
        :tags="tags"
        :topics="topics"
      />
    </b-modal>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col >
        <b-card class="mb-3">
          <div v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
          <span v-if="!saving">
          <h3>New notification</h3>
          <div>Your email notification will contain the latest news items. You can narrow down your selection using the following filters:</div>
          <!-- FILTER BY TOPICS -->
          <b-form-checkbox v-model="filterBySources" name="check-button" switch>
            Filter by sources
          </b-form-checkbox>
          <div v-if="filterBySources">
          <b-form inline>
            <b-button variant="outline-primary" class="mb-2" @click="collapseVisible = !collapseVisible">
              Publishers: {{publishersSelected.length}} out of {{publishersOptions.length}}
            </b-button>
          </b-form>
          <b-collapse id="collapse-1" class="mt-2" v-model="collapseVisible">
            <b-form-group label="Publishers" class="mb-4">
              <div>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="allPublishers">all</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="nonePublishers">clear</b-button>
              </div>
              <div v-if="$config.BUILD === 'full'">
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('ukcore')">UK Core</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('eucore')">EU Core</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('intcore')">International Core</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('uscore')">US Core</b-button>
              </div>
              <div v-if="$config.BUILD === 'full'">
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level1')">RRIC Level 1</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level2')">RRIC Level 2</b-button>
              </div>
              <div>
                <b-button v-b-toggle.collapse-1 variant="outline-primary" class="mb-2 mr-2" size="sm">Collapse</b-button>
              </div>
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="publishersSelected"
                :options="publishersOptions"
                name="publishers"
                stacked
              ></b-form-checkbox-group>
              <div>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="allPublishers">all</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="nonePublishers">clear</b-button>
              </div>
              <div v-if="$config.BUILD === 'full'">
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('ukcore')">UK Core</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('eucore')">EU Core</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('intcore')">International Core</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('uscore')">US Core</b-button>
              </div>
              <div v-if="$config.BUILD === 'full'">
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level1')">RRIC Level 1</b-button>
                <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="custom('level2')">RRIC Level 2</b-button>
              </div>
              <div>
                <b-button v-b-toggle.collapse-1 variant="outline-primary" class="mb-2 mr-2" size="sm">Collapse</b-button>
              </div>
            </b-form-group>
          </b-collapse>
          </div>

          <!-- FILTER BY TOPICS -->
          <b-form-checkbox v-model="filterByTopics" name="check-button" switch>
            Filter by topics
          </b-form-checkbox>
          <div v-if="filterByTopics">
          <b-button variant="outline-primary" class="mb-2" @click="collapseVisible2 = !collapseVisible2">
              Topics: {{topicsSelected.length}} out of {{topicsOptions.length}}
          </b-button>
          <b-collapse id="collapse-2" class="mt-2" v-model="collapseVisible2">
            <div>
              <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="allTopics">all</b-button>
              <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="noneTopics">clear</b-button>
            </div>
            <b-form-group label="Topics" class="mb-4">
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="topicsSelected"
                :options="topicsOptions"
                name="topics"
                stacked
              ></b-form-checkbox-group>              <div>
              <b-button v-b-toggle.collapse-2 variant="outline-primary" class="mb-2 mr-2" size="sm">Collapse</b-button>
              </div>
            </b-form-group>
          </b-collapse>
          </div>

          <!-- FILTER BY TAGS -->
          <b-form-checkbox v-model="filterByTags" name="check-button" switch>
            Filter by tags
          </b-form-checkbox>
          <div v-if="filterByTags">
          <b-button variant="outline-primary" class="mb-2" @click="collapseVisible3 = !collapseVisible3">
              Tags: {{tagsSelected.length}} out of {{tagsOptions.length}}
          </b-button>
          <b-collapse id="collapse-3" class="mt-2" v-model="collapseVisible3">
            <div>
              <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="allTags">all</b-button>
              <b-button variant="outline-primary" class="mb-2 mr-2" size="sm" v-on:click="noneTags">clear</b-button>
            </div>
            <b-form-group label="Tags" class="mb-4">
              <b-form-checkbox-group
                id="checkbox-group-3"
                v-model="tagsSelected"
                :options="tagsOptions"
                name="tags"
                stacked
              ></b-form-checkbox-group>
              <div>
                <b-button v-b-toggle.collapse-3 variant="outline-primary" class="mb-2 mr-2" size="sm">Collapse</b-button>
              </div>
            </b-form-group>
          </b-collapse>
          </div>

          <div class="mt-4">How often would you like to receive updates?</div>
          <b-form-select v-model="frequencySelected" :options="frequencyOptions"></b-form-select>

          <div class="mt-4">You can set the email subject: <strong>"{{ subject }}"</strong></div>
          <b-form-input v-model="subjectCustom" placeholder="Enter custom email subject"></b-form-input>

          <div class="mt-4">Recipient's email</div>
          <b-form-input v-model="email" placeholder="Enter email" type="email"></b-form-input>

          <div class="mt-4" v-if="atLeasOneFilter">
            <b-button variant="primary" v-on:click="saveNotification">Save</b-button>
          </div>
          </span>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import sourcesGroups from '../libs/sourcesGroups.js'

import NotificationItem from '@/components/NotificationItem.vue'

export default {
  components: {
    NotificationItem
  },
  computed: {
    atLeasOneFilter: function () {
      return this.notificationConfig.filterByTopics || this.notificationConfig.filterBySources || this.notificationConfig.filterByTags
    },
    notificationConfig: function () {
      return {
        filterBySources: this.filterBySources,
        filterByTopics: this.filterByTopics,
        filterByTags: this.filterByTags,
        frequency: this.frequencySelected,
        publishers: this.filterBySources ? this.publishersSelected : [],
        tags: this.filterByTags ? this.tagsSelected : [],
        topics: this.filterByTopics ? this.topicsSelected : []
      }
    },
    subject: function () {
      return this.subjectCustom !== '' ? this.subjectCustom : this.subjectDefault
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    const favTopics = window.localStorage.getItem('frmCloudFavTopics')
    if (favTopics) {
      this.favTopics = JSON.parse(favTopics)
    }
    let response = await this.$Amplify.API.get('cosmos', '/notifications/bootstrap')
    this.tags = response.tags
    this.publishers = response.sources
    let topicsTemp = response.topics
    _.remove(topicsTemp, x => {
      return x.name.includes('ARCHIVED')
    })
    this.topics = topicsTemp
    this.tagsOptions = _.map(this.tags, x => { return { text: x.name, value: x.id } })
    this.tagsSelectedDefault = _.map(this.tags, x => { return x.id })
    this.tagsSelected = this.tagsSelectedDefault
    this.topicsOptions = _.map(this.topics, x => { return { text: x.name, value: x.id } })
    if (this.favTopics) {
      this.topicsSelectedDefault = this.favTopics
    } else {
      this.topicsSelectedDefault = _.map(this.topics, x => { return x.id })
    }
    this.topicsSelected = this.topicsSelectedDefault
    this.publishers.forEach(element => this.publishersOptions.push({ text: element, value: element }))
    this.publishersSelectedDefault = response.sources
    this.publishersSelected = this.publishersSelectedDefault
    this.load()
  },
  data () {
    return {
      active: false,
      collapseVisible: false,
      collapseVisible2: false,
      collapseVisible3: false,
      email: '',
      fields: [
        {
          key: 'email',
          sortable: true
        },
        {
          key: 'subject',
          sortable: true
        },
        {
          key: 'frequency',
          sortable: true
        }
      ],
      filter: '',
      filterBySources: false,
      filterByTopics: false,
      filterByTags: false,
      favTopics: null,
      frequencyOptions: [
        { text: 'daily', value: 'daily' },
        { text: 'Monday', value: 'Monday' },
        { text: 'Tuesday', value: 'Tuesday' },
        { text: 'Wednesday', value: 'Wednesday' },
        { text: 'Thursday', value: 'Thursday' },
        { text: 'Friday', value: 'Friday' }
      ],
      frequencySelected: 'daily',
      loading: true,
      notification: {},
      notifications: [],
      publishers: [],
      publishersSelected: [],
      publishersSelectedDefault: [],
      publishersOptions: [],
      saving: false,
      subjectDefault: 'Alert',
      subjectCustom: '',
      tags: [],
      tagsSelected: [],
      tagsSelectedDefault: [],
      tagsOptions: [],
      topics: [],
      topicsSelected: [],
      topicsSelectedDefault: [],
      topicsOptions: []
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.notifications = await this.$Amplify.API.get('cosmos', `/notificationsexternal`)
        this.loading = false
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
    },
    saveNotification: async function () {
      this.saving = true
      this.$logger.debug('loading started')
      try {
        const params = {
          body: {
            config: this.notificationConfig,
            email: this.email,
            frequency: this.frequencySelected,
            subject: this.subject
          }
        }
        await this.$Amplify.API.put('cosmos', '/notificationexternal', params)
        this.resetNotificationConfig()
        this.saving = false
        this.load()
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
    },
    resetNotificationConfig: function () {
      this.filterBySources = false
      this.filterByTopics = false
      this.filterByTags = false
      this.frequencySelected = 'daily'
      this.publishersSelected = this.publishersSelectedDefault
      this.tagsSelected = this.tagsSelectedDefault
      this.topicsSelected = this.topicsSelectedDefault
      this.subjectCustom = ''
    },
    allPublishers: function () {
      this.publishersSelected = this.publishers
    },
    allTags: function () {
      this.tagsSelected = _.map(this.tags, x => { return x.id })
    },
    allTopics: function () {
      this.topicsSelected = _.map(this.topics, x => { return x.id })
    },
    custom: function (selection) {
      let newSelection = _.uniq(_.concat(this.publishersSelected, sourcesGroups[selection]))
      this.publishersSelected = newSelection
    },
    nonePublishers: function () {
      this.publishersSelected = []
    },
    noneTags: function () {
      this.tagsSelected = []
    },
    noneTopics: function () {
      this.topicsSelected = []
    },
    showNotification: async function (notification, index, event) {
      this.notification = notification
      this.active = true
      this.$refs['modal'].show()
    }
  }
}
</script>
